<template lang="html">
  <!-- Button trigger modal -->
  <div class="d-grid">
    <button
      type="button"
      class="btn btn-secondary mb-3"
      data-bs-toggle="modal"
      :data-bs-target="'#' + id"
    >
      Bekijk video
      <i class="bi bi-youtube"></i>
    </button>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    :id="id"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <div class="ratio ratio-16x9">
            <iframe :src="url" title="YouTube video" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalComponent",
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      id: null,
    };
  },
  created() {
    this.id = this.createRandKey();
    console.log(this.id);
  },
  methods: {
    createRandKey() {
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let result = "";

      for (let i = 0; i < 8; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }

      return result;
    },
  },
};
</script>

<style lang="css" scoped></style>
