<template>
  <div class="container bg-white">
    <div class="course-container">
      <div class="row p-3 pb-0 skill-section">
        <div class="row p-3 pb-0">
          <div class="col-md-8 offset-md-2">
            <h1>Demo Online CursusBoek</h1>
            <p class="lead">
              Deze visuele demonstratie bevat minimale tot geen werkende
              functionaliteit en dient voornamelijk als ondersteuning voor het
              ontwerpconcept.
            </p>
            <p>
              Waarin we de Paragrafen 3.1 en 3.2 van hoofdtstuk 3 hebben
              gebruikt ter illustratie.
            </p>
          </div>
        </div>
        <div class="row p-3 pb-0">
          <div class="col-md-8 offset-md-2"></div>
        </div>
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <ul>
            <li>
              <h4>Stijl en opmaak</h4>
              <ul>
                <li><h5>Huisstijl</h5></li>
                <li><h5>Responsiveness</h5></li>
              </ul>
            </li>
            <li>
              <h4>Navigatie</h4>
              <ul>
                <li>
                  <h5>Hiërarchie</h5>
                </li>
              </ul>
            </li>
            <li>
              <h4>Toolbar</h4>
              <ul>
                <li><h5>Inhoud</h5></li>
                <li><h5>Zoeken</h5></li>
                <li><h5>Bookmarks</h5></li>
                <li><h5>Taal</h5></li>
                <li>
                  <h5>Visuele hulpmiddelen</h5>
                  <ul>
                    <li>Vergroten en verkleinen tekst</li>
                    <li>Verborgen elementen om context te geven aan content</li>
                    <li>
                      Gebruik letertypes als Lexend voor mensen met dyslexie
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <h4>Aanvullende functionaliteiten</h4>
              <ul>
                <li>
                  <h5>Interactieve elementen</h5>
                  <ul>
                    <li>Content verbergen/tonen voor leerdoeleinden</li>
                  </ul>
                </li>
                <li><h5>Ondersteunende video</h5></li>
                <li><h5>Kennis Quiz</h5></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="row p-5 pb-0"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
};
</script>

<style lang="css" scoped>
.skill-section {
  background-color: #faf5e6;
}
</style>
