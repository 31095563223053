<template lang="html">
  <div class="container bg-white">
    <div class="row p-3 pb-0 breadcrumb-container">
      <nav aria-label="breadcrumb float-start">
        <ol class="breadcrumb">
          <li class="breadcrumb-item text-white"><a href="/">Home</a></li>
          <li class="breadcrumb-item text-white">
            <a href="/courses">Cursusboeken</a>
          </li>
          <li class="breadcrumb-item text-white active" aria-current="page">
            Cursusboek EHBO
          </li>
        </ol>
      </nav>
    </div>
    <ToolBar />
    <ChapterBar />
    <div class="course-container">
      <div class="row p-3 pb-0">
        <!-- <div class="col-md-8 offset-md-2">
          <h1>Cursusboek EHBO</h1>
        </div> -->
      </div>
      <div class="row p-3 pb-0 next-section">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <div class="d-grid">
            <button class="btn btn-md btn-outline-primary">
              <i class="bi bi-arrow-left"></i>
              2.4 Verleen verdere Eerste hulp
            </button>
          </div>
        </div>
      </div>
      <div class="row p-3 pb-0">
        <div class="col-md-8 offset-md-2">
          <!-- <h2>3 Levensreddend handelen</h2>
          <hr /> -->
          <h3>3.1 Een slachtoffer verplaatsen in een noodsituatie</h3>
          <p>
            Laat het slachtoffer zichzelf verplaatsen als dat kan. Ondersteun
            hem eventueel bij het hinkelen als hij een been niet kan gebruiken.
            Kan het slachtoffer niet lopen of hinkelen? Voer dan een
            noodverplaatsing uit. <br /><br />
            Kun je een liggend slachtoffer op geen andere manier verplaatsen?
            Versleep hem dan aan zijn armen, zijn enkels of zijn kleding over
            een korte afstand. Doe dat het liefst op een vlakke ondergrond.
          </p>
        </div>
      </div>
      <div class="row p-3 pb-0 skill-section mb-4">
        <div class="col-md-2">
          <div class="d-grid">
            <button class="btn btn-info text-light mb-3" type="button">
              <i class="bi bi-file-earmark-check"></i>
              <span class="visually-hidden">Onderdeel:</span>
              Vaardigheid
            </button>
          </div>
        </div>
        <div class="col-md-8">
          <h3 class="skill">Het uitvoeren van een noodverplaatsing</h3>
          <p>
            <strong>Doel</strong>: een slachtoffer dat niet kan lopen of
            hinkelen in veiligheid brengen.
          </p>
          <p>
            <strong>Knielen en handen onder schouders</strong>
          </p>
          <ul>
            <li>Kniel achter het hoofd van het slachtoffer.</li>
            <li>Schuif je handen onder zijn schouders tot bij de oksels.</li>
          </ul>
          <img src="../assets/3_1_1.png" alt="" width="50%" />
          <p><strong>Het slachtoffer leunt tegen je aan</strong></p>
          <ul>
            <li>
              Breng het bovenlichaam van het slachtoffer gedeeltelijk omhoog.
            </li>
            <li>
              Schuif zo dicht mogelijk tegen het slachtoffer aan en breng een
              knie en bovenbeen onder zijn rug. Het slachtoffer leunt nu als het
              ware tegen je aan.
            </li>
            <li>Breng je armen onder de oksels van het slachtoffer door.</li>
            <li>
              Breng één onderarm van het slachtoffer horizontaal voor zijn
              borst.
            </li>
            <li>
              Leg je handen met aaneengesloten vingers en duimen over dezelfde
              arm.
            </li>
          </ul>
          <img src="../assets/3_1_2.png" alt="" width="50%" />
          <p>
            <strong>Naar hurkzit</strong>
          </p>
          <ul>
            <li>
              Ga vanuit knielhouding in hurkzit over. Let er vooral op dat je
              dat doet met gestrekte rug.
            </li>
          </ul>
          <p>
            <strong>Opstaan en het slachtoffer optillen en verslepen</strong>
          </p>
          <ul>
            <li>
              Sta op; laat de kracht voornamelijk uit je bovenbenen komen. Blijf
              er op letten dat je je rug gestrekt houdt.
            </li>
            <li>
              Til het slachtoffer op en versleep hem achterwaarts weg uit de
              gevarenzone.
            </li>
            <li>
              Leg het slachtoffer buiten de gevarenzone weer voorzichtig neer.
            </li>
          </ul>
          <p>
            <strong>
              Bij het neerleggen van het slachtoffer voer je de techniek in
              omgekeerde volgorde uit
            </strong>
          </p>
          <ul>
            <li>Ga in lichte spreidstand staan.</li>
            <li>Zak door je knieën, met gestrekte rug, tot in hurkzit.</li>
            <li>Laat de arm van het slachtoffer los.</li>
            <li>Ondersteun de schouders.</li>
            <li>Ondersteun hoofd en nek.</li>
            <li>Leg het slachtoffer voorzichtig neer.</li>
          </ul>
        </div>
        <div class="col-md-2">
          <ModalComponent
            url="https://www.youtube.com/embed/QKNFDNAnXoI?si=OQE6tmGUcGS9dPuH"
          />
        </div>
      </div>
      <div class="row p-3 pb-0 skill-section">
        <div class="col-md-2">
          <div class="d-grid">
            <button class="btn btn-info text-light mb-3" type="button">
              <i class="bi bi-file-earmark-check"></i>
              <span class="visually-hidden">Onderdeel:</span>
              Vaardigheid
            </button>
          </div>
        </div>
        <div class="col-md-8">
          <h3 class="skill">
            Het uitvoeren van een noodverplaatsing uit een auto
          </h3>
          <p>
            <strong>Doel:</strong> een slachtoffer dat niet kan lopen of
            hinkelen vanuit een auto in veiligheid brengen.
          </p>
          <ul>
            <li>
              Zet het slachtoffer voorzichtig tegen de rugleuning en maak de
              veiligheidsriem los.
            </li>
            <li>Maak de voeten van het slachtoffer vrij.</li>
            <li>Schuif de autostoel zo ver mogelijk achteruit.</li>
            <li>Zet je been tegen de auto of de autostoel en zoek steun.</li>
            <li>
              Steek je beide handen onder de oksels en grijp de verste arm.
            </li>
            <li>
              Houd met je ene hand de pols vast en met je andere hand de
              onderarm.
            </li>
            <li>
              Draai het slachtoffer lichtjes en trek hem naar je toe tot hij
              steunt op je dijbeen.
            </li>
            <li>Strek je benen zodat je recht komt te staan.</li>
            <li>Stap achterwaarts en sleep het slachtoffer mee.</li>
            <li>
              Breng het slachtoffer naar een veilige plaats en leg hem neer.
            </li>
          </ul>
        </div>
        <div class="col-md-2">
          <ModalComponent
            url="https://www.youtube.com/embed/RzN-F-ISZdc?si=cypr-LFn8so6Ey5u"
          />
        </div>
      </div>
      <div class="row p-3 pb-0 next-section pb-5">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <div class="d-grid">
            <router-link
              class="btn btn-md btn-primary"
              to="/course3/cursusboek-basis-ehbo"
            >
              3.2 Bewusteloos slachtoffer met normale ademhaling
              <i class="bi bi-arrow-right"></i>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ToolBar from "@/components/ToolBar.vue";
import ChapterBar from "@/components/ChapterBar.vue";
import ModalComponent from "@/components/ModalComponent.vue";

export default {
  name: "CoursesView2",
  components: {
    ToolBar,
    ChapterBar,
    ModalComponent,
  },
};
</script>

<style lang="css" scoped>
.breadcrumb-container {
  background-color: #2f5f6e;
}
.breadcrumb-container a {
  color: white !important;
  text-decoration: underline;
}
h4.price {
  font-weight: bold;
}
h4 span {
  font-size: 0.75em;
  font-weight: lighter;
}
.skill-section {
  background-color: #faf5e6;
}
h3.skill {
  color: #dc3545;
}
</style>
