<template lang="html">
  <div class="col">
    <div class="card">
      <img
        src="../assets/EHBO_Cover.png"
        class="card-img-top p-3 pb-0"
        alt="..."
      />
      <div class="card-body">
        <h5 class="card-title">Cursusboek EHBO</h5>
        <!-- <ul>
          <li>Toegankelijke basiscursus EHBO inclusief reanimatie en AED</li>
          <li>8 uur klassikaal</li>
          <li>Rode Kruis certificaat</li>
        </ul>
        <h4 class="price"><span class="vanaf">Vanaf</span> € 155,-</h4> -->
        <div class="d-grid">
          <a href="/course/cursusboek-basis-ehbo" class="btn btn-secondary">
            Bekijk
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CourseCard",
};
</script>

<style lang="css" scoped></style>
