<template lang="html">
  <!-- <div class="row">
    <div class="col-md-8 offset-md-2">
      <div class="p-2 chapterbar-container text-white">
        Hoofdstuk:&nbsp;&nbsp;&nbsp;3 Levensreddend handelen
      </div>
    </div>
  </div> -->
  <div class="p-2 chapterbar-container text-white">
    Hoofdstuk:&nbsp;&nbsp;&nbsp;3 Levensreddend handelen
  </div>
</template>

<script>
export default {
  name: "ChapterBar",
};
</script>

<style lang="css" scoped>
.chapterbar-container {
  background-color: #2f5f6e;
}
</style>
