<template lang="html">
  <!-- Button trigger modal -->
  <div class="d-grid">
    <button
      type="button"
      class="btn btn-secondary mb-3"
      data-bs-toggle="modal"
      data-bs-target="#ModalQuiz"
    >
      Doe de Kennis Quiz
      <i class="bi bi-patch-question-fill"></i>
    </button>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="ModalQuiz"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Kennis Quiz</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" v-if="!result">
          <div class="accordion" id="accordionExample">
            <div
              class="accordion-item"
              v-for="(question, index) in questions"
              v-bind:key="question"
            >
              <h2 class="accordion-header">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="'#collapse' + question.vraagid"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <strong>Vraag {{ question.vraagid }}:</strong>
                  &nbsp;&nbsp;{{ question.vraag }}
                </button>
              </h2>
              <div
                :id="'collapse' + question.vraagid"
                class="accordion-collapse collapse"
                :class="{ show: index === 0 }"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div
                    class="d-grid mb-2"
                    v-for="antwoord in question.antwoorden"
                    v-bind:key="antwoord"
                  >
                    <input
                      type="radio"
                      class="btn-check"
                      :name="question.vraagid"
                      :id="question.vraagid + antwoord.antwoordid"
                      autocomplete="off"
                      @click="addToList(question.vraagid, antwoord.antwoordid)"
                    />
                    <label
                      class="btn btn-outline-secondary"
                      :for="question.vraagid + antwoord.antwoordid"
                    >
                      {{ antwoord.antwoord }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-grid mt-2 mb-2">
            <button
              type="button"
              class="btn btn-primary"
              :class="answered.length > 3 ? '' : 'disabled'"
              @click="result = true"
            >
              Bekijk het resultaat
            </button>
          </div>
        </div>
        <!-- Result -->
        <div class="modal-body" v-if="result">
          <div class="">
            <h3>Resultaat:</h3>
            <p>Je hebt 2 vragen goed!</p>

            <div class="d-grid">
              <button
                class="btn btn-info"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                Toon antwoorden
              </button>
            </div>
            <div class="collapse" id="collapseExample">
              <div class="card card-body">
                <h4>Antwoorden:</h4>
                <ul>
                  <li v-for="question in questions" v-bind:key="question">
                    <h6>
                      <strong>Vraag: {{ question.vraagid }}</strong>
                      {{ question.vraag }}
                    </h6>
                    <ul>
                      <li>{{ question.antwoorden[1].antwoord }}</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="d-grid mt-2 mb-2">
            <button
              type="button"
              class="btn btn-primary"
              :class="answered.length > 3 ? '' : 'disabled'"
              @click="result = false"
            >
              Probeer opnieuw
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalQuiz",
  data() {
    return {
      result: false,
      answered: [],
      questions: [
        {
          vraagid: "1",
          vraag: "Wanneer bel je 112?",
          antwoorden: [
            {
              antwoordid: "A",
              antwoord: "Direct bij het aankomen bij het slachtoffer",
            },
            {
              antwoordid: "B",
              antwoord:
                "Op het moment dat het slachtoffer in de stabiele zijligging ligt",
            },
            {
              antwoordid: "C",
              antwoord: "Alleen als het slachtoffer niet reageert",
            },
          ],
        },
        {
          vraagid: "2",
          vraag:
            "Wordt bij een bewusteloos slachtoffer de ademhaling belemmerd",
          antwoorden: [
            {
              antwoordid: "A",
              antwoord: "ja",
            },
            {
              antwoordid: "B",
              antwoord: "Nee",
            },
            {
              antwoordid: "C",
              antwoord: "Misschien",
            },
          ],
        },
        {
          vraagid: "3",
          vraag:
            "Aan welke zijde blijf je bij het omdraaien van een slachtoffer",
          antwoorden: [
            {
              antwoordid: "A",
              antwoord: "ja",
            },
            {
              antwoordid: "B",
              antwoord: "Nee",
            },
            {
              antwoordid: "C",
              antwoord: "Misschien",
            },
          ],
        },
      ],
    };
  },
  created() {},
  methods: {
    addToList(id, answer) {
      this.answered[id] = answer;
      console.log(this.answered);
    },
  },
};
</script>

<style lang="css" scoped>
.modal-content {
  /* background-color: #faf5e6 !important; */
}
</style>
