import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import CoursesView from "../views/CoursesView.vue";
import CourseView from "../views/CourseView.vue";
import CourseView2 from "../views/CourseView2.vue";
import CourseView3 from "../views/CourseView3.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/courses",
    name: "courses",
    component: CoursesView,
  },
  {
    path: "/course/:id",
    name: "course",
    component: CourseView,
  },
  {
    path: "/course2/:id",
    name: "course2",
    component: CourseView2,
  },
  {
    path: "/course3/:id",
    name: "course3",
    component: CourseView3,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Using the variables to satisfy ESLint, even if not directly utilized
    if (to || from || savedPosition) {
      // This block is intentionally left blank as the variables are not needed here
    }
    return { top: 0 }; // Scrolls to the top of the page on route change
  },
});

export default router;
