<template lang="html">
  <div class="container bg-white">
    <div class="row p-3 pb-0 breadcrumb-container">
      <nav aria-label="breadcrumb float-start">
        <ol class="breadcrumb">
          <li class="breadcrumb-item text-white"><a href="/">Home</a></li>
          <li class="breadcrumb-item text-white">
            <a href="/courses">Cursusboeken</a>
          </li>
          <li class="breadcrumb-item text-white active" aria-current="page">
            Cursusboek EHBO
          </li>
        </ol>
      </nav>
    </div>
    <ToolBar />
    <ChapterBar />
    <div class="course-container">
      <div class="row p-3 pb-0">
        <!-- <div class="col-md-8 offset-md-2">
          <h1>Cursusboek EHBO</h1>
        </div> -->
      </div>
      <div class="row p-3 pb-0 next-section">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <div class="d-grid">
            <router-link
              class="btn btn-md btn-outline-primary"
              to="/course2/cursusboek-basis-ehbo"
            >
              <i class="bi bi-arrow-left"></i>
              3.1 Een slachtoffer verplaatsen in een noodsituatie
            </router-link>
          </div>
        </div>
      </div>
      <div class="row p-3 pb-0">
        <div class="col-md-8 offset-md-2">
          <!-- <h2>3 Levensreddend handelen</h2>
          <hr /> -->
          <h3>3.2 Bewusteloos slachtoffer met normale ademhaling</h3>
          <p>
            Bel 112 als het slachtoffer niet reageert op aanspreken en schudden
            en dus bewusteloos is. Als je er zeker van bent dat een bewusteloos
            slachtoffer normaal ademt, leg hem dan op zijn zij, bij voorkeur in
            de stabiele zijligging.<br /><br />
            Bij een bewusteloos slachtoffer verliezen de spieren hun spanning.
            De ademhaling wordt belemmerd, waardoor het slachtoffer kan stikken.
            Door het hoofd een beetje achterover te kantelen met de ‘kinlift’ (→
            Zie:
            <a href="#" class="underline"
              >Herkennen van bewusteloosheid en alarmeren, Open de luchtweg)</a
            >
            of het slachtoffer op de zij te draaien wordt de luchtweg
            opengehouden. Daarmee voorkom je dat de tong, die slap is geworden,
            de luchtweg blokkeert.
          </p>
        </div>
      </div>
      <div class="row p-3 pb-0 skill-section mb-4">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <div class="d-grid">
            <a
              class="btn btn-danger text-light mb-3"
              data-bs-toggle="collapse"
              href="#collapseExample"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <strong>Wat</strong> stel je vast?&nbsp;
              <i class="bi bi-eye"></i>
            </a>
          </div>
          <div class="collapse mb-3" id="collapseExample">
            <div class="card card-body">
              <p>
                <strong>Het slachtoffer:</strong>
              </p>
              <ul>
                <li>
                  reageert niet op aanspreken en schudden, is dus bewusteloos;
                </li>
                <li>ademt normaal, heeft dus een effectieve ademhaling.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row p-3 pb-0 skill-section mb-4">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <div class="d-grid">
            <a
              class="btn btn-dark text-light mb-3"
              data-bs-toggle="collapse"
              href="#collapseExample2"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample2"
            >
              <strong>Wat</strong> doe je?&nbsp;
              <i class="bi bi-hand-index"></i>
            </a>
          </div>
          <div class="collapse mb-3" id="collapseExample2">
            <div class="card card-body">
              <p>
                <strong>Constateer je bewusteloosheid?</strong>
              </p>
              <ul>
                <li>
                  Bel of laat 112 bellen en zet je telefoon op de
                  luidsprekerfunctie.
                </li>
                <li>Laat een AED halen als er een beschikbaar is.</li>
                <li>Volg de instructies van de meldkamercentralist op.</li>
                <li>
                  Controleer de ademhaling:
                  <ul>
                    <li>
                      ademt het slachtoffer normaal? Leg hem in de stabiele
                      zijligging;
                      <ul>
                        <li>
                          controleer elke minuut of de ademhaling normaal blijft
                          ;
                        </li>
                        <li>zorg voor beschutting van het slachtoffer;</li>
                      </ul>
                    </li>
                    <li>
                      stopt de ademhaling? Draai het slachtoffer op de rug en
                      start de reanimatie.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row p-3 pb-0">
        <div class="col-md-8 offset-md-2">
          <div class="row mb-3">
            <div class="col-lg-6">
              <p>
                <strong>Wat als de AED beweging waarneemt?</strong><br />
                Het is belangrijk dat niemand het slachtoffer aanraakt tijdens
                de analyse van het hartritme door de AED en het toedienen van de
                schok. Er ontstaat anders onnodig tijdverlies omdat de AED
                opnieuw gaat analyseren. Als je zeker weet dat niemand het
                slachtoffer heeft aangeraakt en de AED geeft aan dat er beweging
                wordt waargenomen, controleer dan de ademhaling van het
                slachtoffer. Alleen als er sprake is van normale ademhaling mag
                je stoppen met de reanimatie. In alle andere gevallen is het
                herstel onvoldoende om de reanimatie te stoppen en ga je door
                met de reanimatie.
              </p>
            </div>
            <div class="col-lg-6">
              <img src="../assets/aed_afb.jpg" alt="" width="100%" />
            </div>
          </div>
          <p>
            Draai een ongevalsslachtoffer in principe niet. Zitten er zichtbaar
            losse voorwerpen in de mond, bijvoorbeeld kapotte gebitsstukken?
            Haal deze dan uit de mond, in de positie waarin het slachtoffer
            ligt. Draai het slachtoffer nog niet op zijn zij om de luchtweg
            veilig te stellen, ondanks dat hij luidruchtig kan ademhalen.
            Gebruik de kinlift (→ Zie:
            <a href="#" class="underline"
              >Herkennen van bewusteloosheid en alarmeren, Open de luchtweg</a
            >
            ) Herkennen van bewusteloosheid en alarmeren, Open de luchtweg) om
            de luchtweg open te houden, bij voorkeur totdat
            ambulancehulpverleners het overnemen. Is er geen andere mogelijkheid
            om de luchtweg open te houden, bijvoorbeeld omdat er meerdere
            slachtoffers zijn of bestaat er kans op verslikking door bloed? Leg
            een bewusteloos ongevalsslachtoffer dan met uiterste voorzichtigheid
            in de stabiele zijligging, ook al is wervelletsel niet uit te
            sluiten.
          </p>
        </div>
      </div>
      <div class="row p-3 pb-0 skill-section mb-4">
        <div class="col-md-2">
          <div class="d-grid">
            <button class="btn btn-info text-light" type="button" name="button">
              <i class="bi bi-file-earmark-check"></i>
              Vaardigheid
            </button>
          </div>
        </div>
        <div class="col-md-8">
          <h3 class="skill">
            Het leggen van een bewusteloos slachtoffer in de stabiele zijligging
          </h3>
          <p>
            <strong>Doel</strong>: vrijhouden van de ademweg van een bewusteloos
            slachtoffer met een normale ademhaling.
          </p>
          <p>
            <strong>Voorbereiding</strong>
          </p>
          <ul>
            <li>112 is al gebeld.</li>
            <li>Verwijder zo nodig de bril van het slachtoffer.</li>
            <li>
              Kniel naast het slachtoffer en zorg ervoor dat zijn benen gestrekt
              zijn.
            </li>
          </ul>
          <img src="../assets/3_3_1.png" alt="" width="50%" />
          <p>
            <strong>Pak de arm van het slachtoffer</strong>
          </p>
          <ul>
            <li>
              Leg de dichtstbijzijnde arm van het slachtoffer in een rechte hoek
              met het lichaam.
            </li>
          </ul>
          <img src="../assets/3_3_2.png" alt="" width="50%" />
          <p>
            <strong>Houd de handrug van de andere arm tegen de wang</strong>
          </p>
          <ul>
            <li>
              Buig de andere arm over de borst en leg deze met de handrug tegen
              de wang van het slachtoffer. Houd deze hand vast.
            </li>
          </ul>
          <img src="../assets/3_3_3.png" alt="" width="50%" />
          <p>
            <strong>Pak het been en draai het slachtoffer</strong>
          </p>
          <ul>
            <li>
              Je hebt de andere hand vrij om de knie van het verst liggende been
              te buigen, terwijl de voet op de grond blijft.
            </li>
            <li>
              Trek dit gebogen been naar je toe, terwijl je de hand van het
              slachtoffer tegen zijn wang houdt. De heup en de knie van het
              bovenste been moeten in een rechte hoek liggen.
            </li>
            <li>Zorg dat de elleboog de grond raakt.</li>
          </ul>
          <img src="../assets/3_3_4.png" alt="" width="50%" />
          <p>
            <strong>
              Houd de luchtweg vrij en blijf de ademhaling controleren
            </strong>
          </p>
          <ul>
            <li>
              Controleer steeds de ademhaling door te kijken, luisteren en te
              voelen. Door met gespreide vingers de handen op borst en rug te
              leggen kun je de ademhaling goed voelen. Controleer tien seconden
              lang.
            </li>
          </ul>
          <img src="../assets/3_3_5.png" alt="" width="50%" />
        </div>
        <div class="col-md-2">
          <ModalComponent
            url="https://www.youtube.com/embed/0-J1XWZZSd4?si=_YqzGYlDotbmloAF"
          />
        </div>
      </div>
      <div class="row p-3 pb-0 next-section pb-5">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <ModalQuiz />
          <div class="d-grid">
            <button class="btn btn-md btn-primary">
              3.3 Herkennen van bewusteloosheid en alarmeren
              <i class="bi bi-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ToolBar from "@/components/ToolBar.vue";
import ChapterBar from "@/components/ChapterBar.vue";
import ModalComponent from "@/components/ModalComponent.vue";
import ModalQuiz from "@/components/ModalQuiz.vue";

export default {
  name: "CoursesView3",
  components: {
    ToolBar,
    ChapterBar,
    ModalComponent,
    ModalQuiz,
  },
};
</script>

<style lang="css" scoped>
.breadcrumb-container {
  background-color: #2f5f6e;
}
.breadcrumb-container a {
  color: white !important;
  text-decoration: underline;
}
h4.price {
  font-weight: bold;
}
h4 span {
  font-size: 0.75em;
  font-weight: lighter;
}
.skill-section {
  background-color: #faf5e6;
}
h3.skill {
  color: #dc3545;
}
a.underline {
  text-decoration: underline;
}
</style>
