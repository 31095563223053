<template lang="html">
  <div class="p-2 toolbar-container">
    <div class="btn-group float-end">
      <button
        type="button"
        class="btn btn-primary btn-sm ms-2 dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i class="bi bi-translate"></i>
      </button>
      <ul class="dropdown-menu">
        <li><a class="dropdown-item active" href="#">Nederlands</a></li>
        <li><a class="dropdown-item" href="#">English</a></li>
      </ul>
    </div>
    <!-- <button class="btn btn-primary btn-sm float-end ms-2">
      <i class="bi bi-translate"></i>
    </button> -->
    <div class="btn-group">
      <button
        type="button"
        class="btn btn-sm btn-primary dropdown-toggle me-2"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i class="bi bi-list-columns"></i>
      </button>
      <ul class="dropdown-menu">
        <li><h6 class="dropdown-header">Inhoud</h6></li>
        <li>
          <router-link
            class="dropdown-item"
            :class="routeName == 'course' ? 'active' : ''"
            to="/course/cursusboek-basis-ehbo"
          >
            Colofon
          </router-link>
        </li>
        <li>
          <a class="dropdown-item" href="#">1&nbsp;&nbsp;Inleiding</a>
        </li>
        <li>
          <a class="dropdown-item" href="#">
            2&nbsp;&nbsp;De 4 stappen van de EHBO
          </a>
        </li>
        <li>
          <router-link
            class="dropdown-item"
            :class="routeName == 'course2' ? 'active' : ''"
            to="/course2/cursusboek-basis-ehbo"
          >
            3&nbsp;&nbsp;Levensreddend handelen
          </router-link>
        </li>
        <li>
          <a class="dropdown-item" href="#">
            4&nbsp;&nbsp;Eerste hulp bij...
          </a>
        </li>
        <li>
          <a class="dropdown-item" href="#">
            5&nbsp;&nbsp;Medische noodsituaties
          </a>
        </li>
        <li>
          <a class="dropdown-item" href="#">
            6&nbsp;&nbsp;Letsels door weersinvloeden
          </a>
        </li>
        <li>
          <a class="dropdown-item" href="#">
            7&nbsp;&nbsp;Letsels door contact met insecten / dieren
          </a>
        </li>
        <li>
          <a class="dropdown-item" href="#">8&nbsp;&nbsp;Kinderziektes</a>
        </li>
        <li>
          <a class="dropdown-item" href="#">
            Bijlage Afkortingen en verwijzingen
          </a>
        </li>
        <li><hr class="dropdown-divider" /></li>
        <li><a class="dropdown-item" href="#">Complete Inhoud</a></li>
      </ul>
    </div>
    <div class="btn-group">
      <button
        type="button"
        class="btn btn-sm btn-primary dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i class="bi bi-search"></i>
      </button>
      <div class="dropdown-menu">
        <form class="p-1 m-1">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="Zoek"
              aria-label="Zoek"
              aria-describedby="button-addon2"
            />
            <button
              class="btn btn-outline-primary"
              type="button"
              id="button-addon2"
            >
              <i class="bi bi-search"></i>
            </button>
          </div>
          <!-- <div class="mb-3 w-75">
            <input
              type="email"
              class="form-control"
              id="exampleDropdownFormEmail1"
              placeholder=""
            />
          </div>
          <button type="submit" class="btn btn-primary">Zoek</button> -->
        </form>
      </div>
    </div>
    &nbsp;
    <button
      class="btn btn-primary btn-sm float-end"
      @click="increaseFontSize()"
    >
      A+
    </button>
    <button
      class="btn btn-primary btn-sm float-end"
      @click="decreaseFontSize()"
    >
      <small>A-</small>
    </button>
    <!-- Add Bookmark -->
    <button class="btn btn-primary btn-sm" @click="setBookmark()">
      <i class="bi bi-bookmark-plus"></i>
    </button>
    <!-- Show Bookmarks -->
    <div class="btn-group">
      <button
        type="button"
        class="btn btn-sm btn-primary dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i class="bi bi-bookmarks"></i>
      </button>
      <ul class="dropdown-menu">
        <li v-for="bookmark in bookmarks" v-bind:key="bookmark">
          <router-link
            class="dropdown-item"
            to="/course2/cursusboek-basis-ehbo"
          >
            {{ bookmark }}
          </router-link>
        </li>
        <li v-if="bookmarks.length > 0"><hr class="dropdown-divider" /></li>
        <li v-if="bookmarks.length > 0">
          <a class="dropdown-item" href="#" @click="clearBookmarks">
            Verwijder Bookmarks
          </a>
        </li>
        <li v-if="bookmarks < 1">
          <div class="dropdown-item">Voeg Bookmarks toe</div>
        </li>
        <li v-if="bookmarks < 1">
          <div class="dropdown-item">
            Door op
            <i class="bi bi-bookmark-plus"></i>
          </div>
        </li>
        <li v-if="bookmarks < 1">
          <div class="dropdown-item">te klikken</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// import "bootstrap/dist/js/bootstrap.bundle.min.js";

export default {
  name: "ToolBar",
  data() {
    return {
      bookmarks: ["3.1 Een slachtoffer verplaatsen in een noodsituatie"],
    };
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
  },
  methods: {
    setBookmark() {
      this.bookmarks = ["3.1 Een slachtoffer verplaatsen in een noodsituatie"];
    },
    clearBookmarks() {
      this.bookmarks = [];
    },
    increaseFontSize() {
      var containers = document.querySelectorAll(".course-container");

      containers.forEach(function (container) {
        var currentSize =
          parseInt(window.getComputedStyle(container).fontSize) || 16;
        var newSize = currentSize + 2; // Change the value (2) as needed

        container.style.fontSize = newSize + "px";

        // Select and adjust headers within the container
        var headers = container.querySelectorAll("h1, h2, h3, h4, h5, h6");
        headers.forEach(function (header) {
          var headerCurrentSize = parseFloat(
            window.getComputedStyle(header).fontSize
          );
          var headerNewSize = headerCurrentSize * (newSize / currentSize);
          // Maintain a ratio

          header.style.fontSize = headerNewSize + "px";
        });
      });
    },
    decreaseFontSize() {
      var containers = document.querySelectorAll(".course-container");

      containers.forEach(function (container) {
        var currentSize =
          parseInt(window.getComputedStyle(container).fontSize) || 16;
        var newSize = currentSize - 2; // Change the value (2) as needed

        container.style.fontSize = newSize + "px";

        // Select and adjust headers within the container
        var headers = container.querySelectorAll("h1, h2, h3, h4, h5, h6");
        headers.forEach(function (header) {
          var headerCurrentSize = parseFloat(
            window.getComputedStyle(header).fontSize
          );
          var headerNewSize = headerCurrentSize * (newSize / currentSize);
          // Maintain a ratio

          header.style.fontSize = headerNewSize + "px";
        });
      });
    },
  },
};
</script>

<style lang="css" scoped>
.toolbar-container {
  background-color: #faf5e6;
}
</style>
