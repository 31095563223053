<template lang="html">
  <div class="container bg-white">
    <div class="row p-3 pb-0 breadcrumb-container">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item text-white"><a href="/">Home</a></li>
          <li class="breadcrumb-item text-white active" aria-current="page">
            Cursusboeken
          </li>
        </ol>
      </nav>
    </div>
    <div class="row p-3 pb-0">
      <h1>Mijn Online Cursusboeken</h1>
      <p>
        Een EHBO-cursus is voor iedereen van belang. Leer hoe je verschillende
        verwondingen behandelt en hoe je als hulpverlener in actie komt wanneer
        iemand hulp nodig heeft. Ook voor professionals is een EHBO-cursus voor
        volwassenen zeer geschikt. Leer in korte tijd specifieke vaardigheden of
        volg een volledige cursus. Kies uit ons uitgebreide aanbod de cursus die
        bij je past.
        <br /><br />
        Wil je liever met jouw familie, vrienden of collega's een
        EHBO-kindercursus volgen? Neem dan hier contact op voor de
        mogelijkheden.
      </p>
    </div>
    <div
      class="row p-4 pt-1 row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-4"
    >
      <CourseCard />
      <CourseCard />
      <CourseCard />
      <CourseCard />
      <CourseCard />
    </div>
  </div>
</template>

<script>
import CourseCard from "@/components/CourseCard.vue";

export default {
  name: "CoursesView",
  components: {
    CourseCard,
  },
};
</script>

<style lang="css" scoped>
.breadcrumb-container {
  background-color: #2f5f6e;
}
.breadcrumb-container a {
  color: white !important;
  text-decoration: underline;
}
h4.price {
  font-weight: bold;
}
h4 span {
  font-size: 0.75em;
  font-weight: lighter;
}
</style>
