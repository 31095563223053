<template lang="html">
  <div class="container bg-white">
    <div class="row p-3 pb-0 breadcrumb-container">
      <nav aria-label="breadcrumb float-start">
        <ol class="breadcrumb">
          <li class="breadcrumb-item text-white"><a href="/">Home</a></li>
          <li class="breadcrumb-item text-white">
            <a href="/courses">Cursusboeken</a>
          </li>
          <li class="breadcrumb-item text-white active" aria-current="page">
            Cursusboek EHBO
          </li>
        </ol>
      </nav>
    </div>
    <ToolBar />
    <div class="course-container">
      <div class="row pb-0">
        <div class="col-md-8 offset-md-2">
          <img
            src="../assets/EHBO_Cover.png"
            class="card-img-top p-3 pb-0"
            alt="..."
          />
        </div>
      </div>
      <div class="row p-3 pb-0">
        <div class="col-md-8 offset-md-2">
          <h1>Cursusboek EHBO</h1>
        </div>
      </div>
      <div class="row p-3 pb-0">
        <div class="col-md-8 offset-md-2">
          <p>
            <strong>Nederlandse Richtlijnen Eerste Hulp 2021</strong><br />
            Alle handelingen voldoen aan de Nederlandse Richtlijnen Eerste Hulp
            2021. Deze richtlijnen zijn onder meer gebaseerd op de International
            first aid, resuscitation and education guidelines 2020 van het IFRC
            (International Federation of Red Cross and Red Crescent Societies)
            en de Nederlandse Richtlijnen Reanimatie 2021.<br />
            Waar de formuleringen in dit cursusboek (lijken) af (te)wijken van
            de richtlijnen mag de lezer erop vertrouwen dat dit onbedoeld is en
            dat de richtlijnen leidend zijn.
          </p>
        </div>
      </div>
      <div class="row p-3 pb-0 skill-section">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <h4>Colofon</h4>
          <p>
            Cursusboek EHBO <br />
            Versie november 2023<br />
            <br />
            <strong>Het Nederlandse Rode Kruis</strong><br />
            Postbus 28120<br />
            2502 KC Den Haag<br />
            ehbo@redcross.nl<br />
            <br />
            <strong>Auteurs/samenstellers</strong><br />
            Team EHBO Rode Kruis<br />
            <br />
            Met dank aan:<br />
            Cees van Romburgh<br />
            Mariette Verheij<br />
            <br />
            <strong>Vormgeving</strong><br />
            Quantes, Den Haag<br />
            <br />
            <strong>© 2023 Het Nederlandse Rode Kruis</strong><br />
            Niets uit deze uitgave mag verveelvoudigd en / of openbaar gemaakt
            worden door middel van druk, fotokopie, microfilm of op welke andere
            wijze ook, zonder voorafgaande schriftelijke toestemming van het
            Rode Kruis. Het Rode Kruis heeft ernaar gestreefd de rechten van
            derden zo goed mogelijk te regelen. Degenen die desondanks menen
            zekere rechten te kunnen gelden, kunnen zich tot het Rode Kruis
            wenden.
          </p>
        </div>
      </div>
      <div class="row p-3 pb-0 next-section pb-5">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <div class="d-grid">
            <button class="btn btn-md btn-primary">
              1 Inleiding
              <i class="bi bi-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ToolBar from "@/components/ToolBar.vue";

export default {
  name: "CoursesView",
  components: {
    ToolBar,
  },
};
</script>

<style lang="css" scoped>
.breadcrumb-container {
  background-color: #2f5f6e;
}
.breadcrumb-container a {
  color: white !important;
  text-decoration: underline;
}
h4.price {
  font-weight: bold;
}
h4 span {
  font-size: 0.75em;
  font-weight: lighter;
}
.skill-section {
  background-color: #faf5e6;
}
h3.skill {
  color: #dc3545;
}
</style>
